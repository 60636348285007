import React from 'react'
import LightboxLayout from '../../components/lightboxLayout'

const Page = () => {
  return (
    <LightboxLayout>
      <h2>ETCH-RETAINED RESTORATIONS</h2>
      <h3>What are they?</h3>
      <p>Many of the newer dental materials are adhesive, which means that they stick or "bond" to teeth. The surface of the tooth needs to be made slightly rough so that the repair (the "restoration") can grip properly. This is called "etching" and your dentist will use a harmless mild acid. "Etch-retained restorations" are any repairs attached to the tooth in this way- either fillings, veneers, inlays or onlays.</p>
      <h3>What will my dentist do?</h3>
      <p>Your dentist will:</p>
      <ul>
        <li>sometimes numb the tooth, but this is not always needed</li>
        <li>dab the etching acid onto the tooth surface that needs to be roughened</li>
        <li>leave the acid on the tooth for a short time while you keep your mouth open</li>
        <li>wash the tooth very thoroughly with a jet of water, which is sucked out of your mouth through a tube held by the dental nurse</li>
        <li>dry the tooth and check the surface (it might need to be etched again, in the same way)</li>
        <li>paint the "bonding agent" (a sort of glue) onto the roughened area, before filling the tooth or applying some other sort of restoration.</li>
      </ul>
      <h3>What are the benefits?</h3>
      <p>The bond between the tooth and the restoration can be very strong so that the restoration stays in place for a long time.</p>
      <p>Even if the bond breaks, it may be possible to re-glue the restoration in place.</p>
      <p>Because restorations are held in place by the bonding agent and not just by the shape of the tooth, less of the natural tooth is lost.</p>
    </LightboxLayout>
  )
}

export default Page